/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllReferrals,
  // getAllUser,
  // getAllOfferCodes
} from '../../../redux/action/admin';
// import timeSince from '../../../utils/timesince';
import STable from '../../modules/cardform/components/helpers/STable';

function Admintools() {
  const ngrokip:any = process.env.REACT_APP_NGROK_FRONTEND_URL;
  const testmode = localStorage.getItem('testmode')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const referrals: any = useSelector((state: any) => state.Admin?.AllReferrals)
  // const token = localStorage.getItem('userId') //omitting due to eslint warning
  // const [dataMyTable, setdataMyTable] = useState<any>([]);
  // const users: any = useSelector((state: any) => state.Admin?.AllUser)
  // const offerCOdes: any = useSelector((state: any) => state.Admin?.AllOfferCodes)

  useEffect(() => {
    const getdata = async () => {
      setIsLoading(true);
      //await dispatch(getAllUser({}))
      await dispatch(getAllReferrals({}))
      console.log('referrals', referrals)
      //await dispatch(getAllOfferCodes({}))
      setIsLoading(false);
      console.log('testmode', testmode)
    }
    getdata();
  }, []);

  const handleReferral = async (type, itemid = '') => {
    console.log('type', type, 'itemid', itemid)
    //await dispatch(updateReferral({ id, status }))
  }

  const handleProductionMode = async () => {
    window.location.href = ngrokip + '?testmode=production';
  }

  const handleStagingMode = async () => {
    window.location.href = ngrokip +  '?testmode=staging';
  }

  const handleLocalMode = async () => {
    localStorage.setItem('testmode', '')
    window.location.href = 'http://localhost:3000'
  }

  return (
    <div style={{margin:'20px'}}>
     <h1 >Administration Tools</h1>
     {ngrokip && (<div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>
      <h3 style={{marginBottom:'10px'}}>Dev Tools</h3>
      {testmode === 'production' ? (
        <>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none', marginRight:'10px'}} onClick={handleStagingMode}>Test in Staging</button>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none'}} onClick={handleLocalMode}>Test with Local Emulators</button>
        </>
      ) : testmode === 'staging' ? (
        <>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none', marginRight:'10px'}} onClick={handleProductionMode}>Test in Production</button>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none'}} onClick={handleLocalMode}>Test with Local Emulators</button>
        </>
      ):(
        <>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none', marginRight:'10px'}} onClick={handleProductionMode}>Test in Production</button>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none'}} onClick={handleStagingMode}>Test in Staging</button>
        </>
      )} 
      
      </div>)}
     <div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>

<h3 style={{marginBottom:'10px'}}>Referrals</h3>
        <STable
        headers={[
          { column: "email", label: "Email" },
          { column: "id", label: "Id" },
          { column: "name", label: "Name" },
          { column: "referred_by", label: "Referred By" },
          { column: "offer_code", label: "Offer Code" },
          { column: "status", label: "Status" }
        ]}
        data={referrals}
        isLoading={isLoading}
        loadingTag={<h1>Loading...</h1>}
        handleReferral={handleReferral}
        type='referrals'
      />
</div>
<div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>

<h3 style={{marginBottom:'10px'}}>Offer Codes</h3>
        <STable
        headers={[
          { column: "code id", label: "Code" },
          { column: "type", label: "Type" },
          { column: "limit", label: "Name" },
          { column: "selfRedeemable", label: "Self Reedeemable" },
          { column: "discount", label: "Discount" },
          { column: "length", label: "length" },
          { column: "expiration", label: "Offer Code" },
          { column: "status", label: "Status" }
        ]}
        data={referrals}
        isLoading={isLoading}
        loadingTag={<h1>Loading...</h1>}
        handleReferral={handleReferral}
        type='referrals'
      />
</div>
     <div style={{border:'1px, solid, black', paddingTop:'10px', paddingLeft:'10px',  marginBottom:'15px'}}>

      <h3 style={{marginBottom:'10px'}}>Invite Users</h3>
      <span>Select Offer Code</span>
      <select name="offertype" id="offertype">
                <option value="trial">Trial</option>
                <option value="discount">Discount</option>
            </select><br></br>
      <span>Enter email addresses separated by commas</span>
      <div style={{margin:'15px'}}>
        <textarea style={{width:'100%'}} rows={5} ></textarea >
        <button style={{marginTop:'10px', padding:'10px', backgroundColor:'blue', color:'white', border:'none'}}>Send Invites</button>
      </div>
      </div>
      <div style={{border:'1px, solid, black', paddingTop:'10px', paddingLeft:'10px',  marginBottom:'15px'}}>

      <h3 style={{marginBottom:'10px'}}>Create Offer Codes</h3>
      <span>Enter email addresses separated by commas</span>
      <div style={{margin:'15px'}}>
        <div className="row">
          <div className="col-3">
            offer type:
            <select name="offertype" id="offertype">
                <option value="trial">Trial</option>
                <option value="discount">Discount</option>
                <option value="trial+discount">Trail & Discount</option>
            </select>

          </div>
          <div className="col-3">
             offer code:<input type="text" placeholder="Enter Value" style={{width:'40%', marginTop:'10px'}} />
          </div>
          <div className="col-3">
             offer value:<input type="number" placeholder="Enter Value" style={{width:'40%', marginTop:'10px'}} />
          </div>
          <div className="col-3">
             offer expiration :<input type="date" id="start" name="trip-start" value="2024-11-01" min="2024-10-05" max="2025-12-31" />
          </div>
          <div className="col-3">
              offer limit:<input type="number" placeholder="Enter Value" style={{width:'40%', marginTop:'10px'}} />
          </div>
      </div>
        <button style={{marginTop:'10px', padding:'10px', backgroundColor:'blue', color:'white', border:'none'}}>Create Offer Code</button>
      </div>
      </div>
    </div>
  )
}

export default Admintools
