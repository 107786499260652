// import { API_URL } from '../../config'
import { generatePopup } from '../../utils/popup'
// import axios from 'axios'
import { auth, firestore } from '../../firebase'
import { getFirestore, collection, doc, setDoc, getDocs, getDoc, updateDoc } from 'firebase/firestore'
// import { rejects } from 'assert'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { AppLog } from '../../_4threal/helpers';
import { Action } from '../actionTypes';


export const getAllUser: any = (payload: any) => (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const userCollection = collection(firestore, 'users');
        const searchTerm = (payload.searchTerm ?? '').toLowerCase();
        const rolesFilter = payload.roles; // Roles to filter by
        const page = payload.page ? payload.page : 1; // Set default page to 1
        const limit = payload.limit ? payload.limit : 10; // Set default limit to 10

        try {
            // Calculate the starting index based on the page and limit
            const startAt = (page - 1) * limit;

            const querySnapshot = await getDocs(userCollection);
            const totalCount = querySnapshot.size;
            const users: any[] = [];
            let index = 0;

            querySnapshot.forEach((doc) => {
                const userData = doc.data();
                const firstName = (userData.firstName || '').toLowerCase(); // Check if firstName is defined
                const lastName = (userData.lastName || '').toLowerCase(); // Check if lastName is defined
                const userRoles = userData.userTypes || ""; // Check if roles are defined

                // Check if either firstName or lastName contains the searchTerm
                if (
                    firstName.includes(searchTerm) ||
                    lastName.includes(searchTerm)
                ) {
                    // Check if userRoles match the rolesFilter and userUsertype matches usertypeFilter
                    if (
                        userRoles.includes(rolesFilter)
                    ) {
                        if (index >= startAt && index < startAt + limit) {
                            const userId = doc.id;
                            users.push({ id: userId, ...userData });
                        }
                        index++;
                    }
                }
            });

            dispatch({
                type: Action.ADMIN_GET_ALLUSER_SUCCESS,
                payload: { users, totalCount },
            });
            resolve(users);
        } catch (error) {
            dispatch({
                type: Action.ADMIN_GET_ALLUSER_FAIL,
                payload: error,
            });
        }
    });
};

export const getAllReferrals: any = (payload: any) => (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: Action.ADMIN_GET_REFERRALS_INIT,
        })

        const userCollection = collection(firestore, 'referrals');
        // const searchTerm = (payload?.searchTerm ?? '').toLowerCase();
        // const rolesFilter = payload?.roles; // Roles to filter by
        // const page = payload?.page ? payload.page : 1; // Set default page to 1
        // const limit = payload?.limit ? payload.limit : 10; // Set default limit to 10

        try {
            // Calculate the starting index based on the page and limit
            // const startAt = (page - 1) * limit;

            const querySnapshot = await getDocs(userCollection)
            // const totalCount = querySnapshot.size;
            let referrals: any[] = [];
            // let index = 0;

            querySnapshot.forEach((doc) => {

                const data = doc.data();
                console.log(data);
                const refid = doc.id;
                let ref = {...data};
                ref['id'] = refid;
                ref['name'] = ref.first_name + ' ' + ref.last_name;
                ref['status'] = 'pending';
                if (ref?.notified_at){
                    ref['status'] = 'notified';
                }
                if (ref?.invited_at){
                    ref['status'] = 'invited';
                }
                if (ref?.joined_at){
                    ref['status'] = 'joined';
                }
                referrals.push({ ...ref });
                /*
                const userData = doc.data();
                const firstName = (userData.firstName || '').toLowerCase(); // Check if firstName is defined
                const lastName = (userData.lastName || '').toLowerCase(); // Check if lastName is defined
                const userRoles = userData.userTypes || ""; // Check if roles are defined

                // Check if either firstName or lastName contains the searchTerm
                if (
                    firstName.includes(searchTerm) ||
                    lastName.includes(searchTerm)
                ) {
                    // Check if userRoles match the rolesFilter and userUsertype matches usertypeFilter
                    if (
                        userRoles.includes(rolesFilter)
                    ) {
                        if (index >= startAt && index < startAt + limit) {
                            const userId = doc.id;
                            users.push({ id: userId, ...userData });
                        }
                        index++;
                    }
                }
                    */
            });

            dispatch({
                type: Action.ADMIN_GET_REFERRALS_SUCCESS,
                payload: referrals,
            });
            resolve(referrals);
        } catch (error) {
            dispatch({
                type: Action.ADMIN_GET_REFERRALS_FAIL,
                payload: error,
            });
        }
    });
};

export const getAllOfferCodes: any = (payload: any) => (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: Action.ADMIN_GET_OFFERCODES_INIT,
        })

        const userCollection = collection(firestore, 'offercodes');
        const searchTerm = (payload.searchTerm ?? '').toLowerCase();
        const rolesFilter = payload.roles; // Roles to filter by
        const page = payload.page ? payload.page : 1; // Set default page to 1
        const limit = payload.limit ? payload.limit : 10; // Set default limit to 10

        try {
            // Calculate the starting index based on the page and limit
            const startAt = (page - 1) * limit;

            const querySnapshot = await getDocs(userCollection);
            const totalCount = querySnapshot.size;
            const users: any[] = [];
            let index = 0;

            querySnapshot.forEach((doc) => {
                const userData = doc.data();
                const firstName = (userData.firstName || '').toLowerCase(); // Check if firstName is defined
                const lastName = (userData.lastName || '').toLowerCase(); // Check if lastName is defined
                const userRoles = userData.userTypes || ""; // Check if roles are defined

                // Check if either firstName or lastName contains the searchTerm
                if (
                    firstName.includes(searchTerm) ||
                    lastName.includes(searchTerm)
                ) {
                    // Check if userRoles match the rolesFilter and userUsertype matches usertypeFilter
                    if (
                        userRoles.includes(rolesFilter)
                    ) {
                        if (index >= startAt && index < startAt + limit) {
                            const userId = doc.id;
                            users.push({ id: userId, ...userData });
                        }
                        index++;
                    }
                }
            });

            dispatch({
                type: Action.ADMIN_GET_OFFERCODES_SUCCESS,
                payload: { users, totalCount },
            });
            resolve(users);
        } catch (error) {
            dispatch({
                type: Action.ADMIN_GET_OFFERCODES_FAIL,
                payload: error,
            });
        }
    });
};

export const Userdownloadexcel: any = (payload: any) => (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: Action.ADMIN_GET_ALLUSER_DOWNLOAD_INIT
        });

        try {
            const userCollection = collection(firestore, 'users');

            // Query Firestore to get all user data
            const querySnapshot = await getDocs(userCollection);
            const users: any[] = [];

            querySnapshot.forEach((doc) => {
                const userData = doc.data();
                const userId = doc.id;
                users.push({ id: userId, ...userData });
            });

            dispatch({
                type: Action.ADMIN_GET_ALLUSER_DOWNLOAD_SUCCESS,
                payload: users,
            });
            resolve(users);
        } catch (error) {
            dispatch({
                type: Action.ADMIN_GET_ALLUSER_DOWNLOAD_FAIL,
                payload: error,
            });
        }
    });
}


export const getUserByid: any = (payload: any) => (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        // const uid = localStorage.getItem('userId')
        dispatch({
            type: Action.GET_USERBY_ID_INIT,
        })

        try {
            const userRef = doc(firestore, `users`, payload)
            const userSnap = await getDoc(userRef)
            if (userSnap.exists()) {
                let res = userSnap.data()
                res['_id'] = userSnap.id
                dispatch({
                    type: Action.GET_USERBY_ID_SUCCESS,
                    payload: res,
                })
                resolve(res)
            } else {
                dispatch({
                    type: Action.GET_USERBY_ID_FAIL,
                    payload: 'Failed to get user!',
                })
                generatePopup('error', 'Could not find User.')
            }
        } catch (error: any) {
            dispatch({
                type: Action.GET_USERBY_ID_FAIL,
                payload: 'Failed to get user!',
            })
            generatePopup('error', 'Could not find User.')
        }
    })
}

export const updateUser: any = (payload: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const { userId, updatedData } = payload;
            dispatch({
                type: Action.UPDATE_USER_INIT,
            });

            const db = getFirestore(); // Initialize Firestore

            const userDocRef = doc(db, 'users', userId); // Reference to the user document

            setDoc(userDocRef, updatedData, { merge: true })
                .then(() => {
                    dispatch({
                        type: Action.UPDATE_USER_SUCCESS,
                    });
                    generatePopup('success', 'User updated successfully.');
                    resolve(true);
                })
                .catch((error) => {
                    AppLog(`Unsuccessful returned error ${error}`);
                    dispatch({
                        type: Action.UPDATE_USER_FAIL,
                        payload: error.message,
                    });
                    reject(error);
                });
        } catch (error) {
            const typedError = error as Error;
            dispatch({
                type: Action.UPDATE_USER_FAIL,
                payload: typedError.message,
            });
            reject(error);
        }
    })
}

export const deleteUserByid: any = (payload: any) => (dispatch: any) => {
    return new Promise<void>((resolve, reject) => {
        dispatch({
            type: Action.DELETE_USER_INIT,
        })

        // Update the user's status to inactive in Firebase Firestore
        const db = getFirestore()
        const userRef = doc(db, 'users', payload)

        updateDoc(userRef, {
            status: 'inactive',
        })
            .then((res) => {
                dispatch({
                    type: Action.DELETE_USER_SUCCESS,
                })
                generatePopup('success', 'User status updated to inactive.')
                resolve(payload)
            })
            .catch((error) => {
                console.error('Error updating user status:', error)
                reject(error)
            })
    })
}

export const addUser: any = (payload: any) => (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: Action.AUTH_REGISTER_INIT,
        });
        try {
            const { email, password, firstName, lastName } = payload;

            // Create the user with email and password
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            // Update the user's display name
            await updateProfile(userCredential.user, { displayName: firstName + ' ' + lastName });

            generatePopup("success", "user Add successfully.");
            resolve(payload);
        } catch (error: any) {
            // Check the Firebase error code
            if (error.code === 'auth/email-already-in-use') {
                // Handle the case of invalid email/password
                generatePopup("error", "Email already registered!");
            } else {
                // Handle other Firebase authentication errors
                generatePopup("error", "An error occurred. Please try again later.");
            }

            dispatch({
                type: Action.AUTH_REGISTER_FAIL,
                payload: typeof error === "string" ? error : "User already registered!",
            });
            resolve(error);
        }
    });
}
