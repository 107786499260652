// All Steps Possible In Scene/Marker Creation

export enum Step {
  // Marker Steps
  UploadMarker_1, TransformQR_2, MarkerSceneLink_3,
  // Scene Steps
  UploadVideo_4, UploadLogo_5, AddLinks_6,
  // Beginning/End Steps
  Dashboard, Publish, PublishMarker,
  // Repeat Steps
  RepeatPublishMarker, BackToProductMenu, PostPublishPrompt,
  // Init Step
  Unknown,
}

// Flow IDs & Dictionary (IDs must exist in dictionary)

export const Flow = {
  full: 'full',
  repeat: 'repeat',
  markeronly: 'markeronly'
}

const FlowConfigs = {
  full: [
    Step.Dashboard,
    Step.UploadMarker_1,
    Step.UploadVideo_4,
    Step.UploadLogo_5,
    Step.AddLinks_6,
    Step.Publish,
    Step.PostPublishPrompt,
  ],
  markeronly: [
    Step.Dashboard,
    Step.UploadMarker_1,
    Step.MarkerSceneLink_3,
    Step.PublishMarker,
  ],
  repeat: [
    Step.BackToProductMenu,
    Step.RepeatPublishMarker,
    Step.PostPublishPrompt,
  ]
}

// Get Next or Previous State

export const GetFirstStep = (flow) => FlowConfigs[flow][1] //the 0th index is reserved for 'Dashboard' step
export const GetNext      = (currStep, flow) => getNext(currStep, FlowConfigs[flow])
export const GetPrevious  = (currStep, flow) => getPrev(currStep, FlowConfigs[flow])

const getNext = (currStep, flow) => flow[flow.indexOf(currStep) + 1]
const getPrev = (currStep, flow) => flow[flow.indexOf(currStep) - 1]

// Helpers

export const IsPublishStep = (currStep) => {
  return currStep === Step.Publish
      || currStep === Step.PublishMarker
      || currStep === Step.RepeatPublishMarker;
}

export const IsMarkerStep = (currStep) => {
  return currStep === Step.UploadMarker_1
      || currStep === Step.TransformQR_2
      || currStep === Step.MarkerSceneLink_3
      || currStep === Step.PublishMarker;
}

export const IsSceneStep = (currStep) => {
  return currStep === Step.UploadVideo_4
      || currStep === Step.UploadLogo_5
      || currStep === Step.AddLinks_6
      || currStep === Step.Publish
      || currStep === Step.RepeatPublishMarker;
}