import React, { useState } from "react";

const TableHeader = ({
    headers,
    onSortColumnChange,
    sortColumn,
    sortDirection,
    selectAll,
    handleSelectAllChange
  }) => {
    const handleHeaderClick = (column) => {
      onSortColumnChange(column);
    };

    return (
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          </th>
          {headers.map((header) => (
            <th
              key={header.column}
              onClick={() => handleHeaderClick(header.column)}
            >
              {header.label}{" "}
              {sortColumn === header.column && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

const TableBody = ({
    headers,
    data,
    currentPage,
    itemsPerPage,
    sortColumn,
    sortDirection,
    isLoading,
    selectedRows,
    handleRowSelection,
    handleReferral
  }) => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;

    const sortedData = [...data].sort((a, b) => {
      const columnA = a[sortColumn];
      const columnB = b[sortColumn];

      if (columnA < columnB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (columnA > columnB) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    const paginatedData = sortedData.slice(startIdx, endIdx);

    return (
      <tbody>
        {!isLoading &&
          paginatedData.map((item) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(item.id)}
                  onChange={() => handleRowSelection(item.id)}
                />
              </td>
              {headers.map((header) => (
                <td key={header.column}>{item[header.column]}</td>
              ))}
               <td>
                <button onClick={() => handleReferral("edit", item.id)}>edit</button>
              </td>
            </tr>
          ))}
      </tbody>
    );
  };

const Pagination = ({
  currentPage,
  totalNumberOfPages,
  handlePageChange,
  maxPageNumbers = 5, // Set the maximum number of page numbers to display
}) => {
  const pageNumbers = Array.from(
    { length: totalNumberOfPages },
    (_, index) => index + 1
  );

  const renderPageNumbers = () => {
    if (totalNumberOfPages <= maxPageNumbers) {
      return pageNumbers;
    }

    const middleIndex = Math.floor(maxPageNumbers / 2);

    if (currentPage <= middleIndex) {
      // Display pages from 1 to maxPageNumbers
      return [
        ...pageNumbers.slice(0, maxPageNumbers - 1),
        "...",
        totalNumberOfPages,
      ];
    } else if (currentPage >= totalNumberOfPages - middleIndex) {
      // Display pages from totalNumberOfPages - maxPageNumbers + 2 to totalNumberOfPages
      return [1, "...", ...pageNumbers.slice(-maxPageNumbers + 1)];
    } else {
      // Display pages around the current page
      const startPage = currentPage - middleIndex + 1;
      const endPage = currentPage + middleIndex - 1;
      return [
        1,
        "...",
        ...pageNumbers.slice(startPage, endPage),
        "...",
        totalNumberOfPages,
      ];
    }
  };

  return (
    <div className="row justify-content-between">
      <div className="col-md-3 col-sm-12 text-start">
        Showing page {currentPage} of {totalNumberOfPages} pages
      </div>
      <div className="col-md-3 col-sm-12 text-end">
        <div className="pagination">
          <li className="page-item">
            <button
              className={"page-link " + (currentPage === 1 ? "disabled" : "")}
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >{`<`}</button>
          </li>
          {renderPageNumbers().map((pageNumber, index) => (
            <li key={index} className="page-item">
              <button
                className={`page-link ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              className={
                "page-link " +
                (currentPage === totalNumberOfPages ? "disabled" : "")
              }
              onClick={() => handlePageChange(totalNumberOfPages)}
              disabled={currentPage === totalNumberOfPages}
            >{`>`}</button>
          </li>
        </div>
      </div>
    </div>
  );
};

const STable = ({ headers, data, isLoading, loadingTag, handleReferral, type }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState(""); // Search state
    const [itemsPerPage, setItemsPerPage] = useState(5); // Items per page
    const [sortColumn, setSortColumn] = useState(headers[0].column); // Sorting column
    const [sortDirection, setSortDirection] = useState("asc"); // Sorting direction
    const [selectedRows, setSelectedRows] = useState<number[]>([]); // Selected rows
    const [selectAll, setSelectAll] = useState<boolean>(false); // Select all state

    const filteredData = data.filter((item) =>
      headers.some((header) =>
        String(item[header.column])
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
    );

    const totalNumberOfPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const handleSortColumnChange = (column) => {
      if (sortColumn === column) {
        setSortDirection((prevDirection) =>
          prevDirection === "asc" ? "desc" : "asc"
        );
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    };

    const handleSearchChange = (e) => {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    };

    const handleRowSelection = (id) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(id)
          ? prevSelectedRows.filter((rowId) => rowId !== id)
          : [...prevSelectedRows, id]
      );
    };

    const handleSelectAllChange = () => {
      setSelectAll(!selectAll);
      setSelectedRows(
        selectAll ? [] : filteredData.map((item) => item.id)
      );
    };

    // const handleAction = (action) => {
    //   // Perform the action on selected rows
    //   console.log(`Performing action: ${action} on rows: `, selectedRows);
    //   // Reset selection after action
    //   setSelectedRows([]);
    //   setSelectAll(false);
    // };

    return (
      <>

        <div className="row justify-content-between">
          <div className="col-4 text-start">
            <div className="input-group">
              <span className="input-group-text">Show</span>
              <div>
                <select
                  className="form-control form-select"
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value, 10));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <span className="input-group-text">entries</span>
            </div>
          </div>
          <div className="col-4 text-center">
          <div className='d-flex justify-content-between gap-3'>
          {type === 'referrals' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral('resend')}> Invite Users</button>)}
          {type === 'offerodes' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral('deactivate')}>Create Offer Code</button>)}
          {selectedRows.length > 0 && (
            <>
                {type === 'referrals' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral('resend')}> Resend/Send Invitations</button>)}
                {type === 'offerodes' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral('deactivate')}>Deactivate Offer</button>)}
                 </>
            /*
        <DropdownButton id="dropdown-basic-button" title="Download Demo Marker" style={{ float: "right" }}>
            <Dropdown.Item as='button' onClick={() => handleReferral('resend')}>Resend/Send Invitation</Dropdown.Item>
            <Dropdown.Item as='button' onClick={() => handleReferral('download')}>Download Spreadsheet</Dropdown.Item>
        </DropdownButton>*/
        )}
            </div>


          </div>
          <div className="col-4 text-end">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={searchValue}
                onChange={handleSearchChange}
                placeholder="Search all columns"
              />
            </div>
          </div>
        </div>
        <br />

        <div className="table-responsive">
          <table className="table table-bordered table-responsive">
            <TableHeader
              headers={headers}
              onSortColumnChange={handleSortColumnChange}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              selectAll={selectAll}
              handleSelectAllChange={handleSelectAllChange}
            />
            <TableBody
              headers={headers}
              data={filteredData}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              isLoading={isLoading}
              selectedRows={selectedRows}
              handleRowSelection={handleRowSelection}
              handleReferral={handleReferral}
            />
          </table>
        </div>

        {isLoading ? (
          <div style={{ textAlign: "center", width: "200px", margin: "0 auto" }}>
            <p>{loadingTag}</p>
          </div>
        ) : (
          ""
        )}

        <Pagination
          currentPage={currentPage}
          totalNumberOfPages={totalNumberOfPages}
          handlePageChange={handlePageChange}
        />
      </>
    );
  };

export default STable;